import { useMemo } from 'react';

import axios, { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { ArtistModel } from '@/models/Artist';
import ArtistAPI from '@/network/ArtistAPI';

import useAccountContext from '../context/useAccountContext';
import useUserTracking from '../useUserTracking';

const useArtist = () => {
  const { account } = useAccountContext();
  const artistId = useMemo(() => account?.artistId, [account?.artistId]);
  const userTracking = useUserTracking();

  const trackUserUpdate = (data: ArtistModel) => {
    userTracking?.updateProfile({
      artistName: data.details.name,
      genres: data.details.genres?.map((genre) => genre),
    });
  };

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<ArtistModel> | null>(
    () => {
      if (!artistId || !account || axios.defaults.headers.common['X-Account-Id'] !== account.id) return null;
      return `artist-data-${artistId}`;
    },
    async () => {
      if (!artistId || !account || axios.defaults.headers.common['X-Account-Id'] !== account.id) return null;
      const result = await ArtistAPI.getArtist({ artistId });
      return result;
    }
  );
  if (data) trackUserUpdate(data.data);
  return {
    artist: data?.data,
    artistIsLoading: isLoading,
    artistError: error,
    refetchArtist: mutate,
  };
};

export default useArtist;
